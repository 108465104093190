// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import * as Routes from './routes.js.erb';
// import "channels"

Rails.start()
// Turbolinks.start()
ActiveStorage.start()

require('@fortawesome/fontawesome-free');

window.Toastify = require('toastify-js');
window.PLURAL = require('pluralize');
window.Routes = Routes;

window.authenticityToken = function() { return document.querySelector('meta[name="csrf-token"]').getAttribute('content'); }
window.notify = (message, success=true, options={}) => {
  Toastify({
    text: message,
    duration: 3000,
    newWindow: true,
    close: true,
    gravity: "top", 
    position: 'right', 
    backgroundColor: success ? "linear-gradient(to right, #00b09b, #96c93d)" : "linear-gradient(to right, #DC143C, #FF4500)",
    className: "toastify-define",
    ...options
  }).showToast();
}